import React, { useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { FaRegUser, FaRegEdit } from 'react-icons/fa';
import { userUpdateURL } from '../api/api';

const Profile = () => {
  const pf = JSON.parse(localStorage.getItem('userData')) || {};

  const [data, setData] = useState({
    firstName: pf.firstName || '',
    lastName: pf.lastName || '',
    email: pf.email || '',
    mobile: pf.mobile || '',
  });

  const [editMode, setEditMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        userUpdateURL,
        { ...data, id: pf.user_id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include your auth token if needed
          },
        }
      );

      alert(response.data.message);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col">
    <div className="bg-gray-100 h-[60vh]">
      <div className="container mx-auto my-5 p-5">
        <div className="md:flex md:space-x-4 no-wrap">
          {/* Status box */}
          <div className="w-full md:w-3/12 mb-4">
            <div className="bg-white p-3 border-t-4 border-green-400">
              <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                {data.firstName || ''} {data.lastName || ''}
              </h1>
              <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                <li className="flex items-center py-3">
                  <span>Status</span>
                  <span className="ml-auto">
                    <span className="bg-green-500 py-1 px-2 rounded text-white text-sm">
                      Active
                    </span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* About box */}
          <div className="w-full md:w-9/12">
            <div className="bg-white p-3 shadow-sm rounded-sm">
              <div className="flex items-center justify-between space-x-2 font-semibold text-gray-900 leading-8">
                <div className="flex items-center gap-4">
                  <span className="text-green-500">
                    <FaRegUser />
                  </span>
                  <span className="tracking-wide">
                    <b>About</b>
                  </span>
                </div>
                <div className="cursor-pointer">
                  <FaRegEdit size={25} onClick={() => setEditMode(!editMode)} />
                </div>
              </div>

              {editMode ? (
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 text-sm">
                    <div className="grid grid-cols-2 items-center">
                      <label className="px-4 py-2 font-bold">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={data.firstName}
                        onChange={handleChange}
                        className="px-4 py-2 border w-full"
                      />
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <label className="px-4 py-2 font-semibold">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={data.lastName}
                        onChange={handleChange}
                        className="px-4 py-2 border w-full"
                      />
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <label className="px-4 py-2 font-semibold">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        className="px-4 py-2 border w-full"
                        style={{ wordBreak: 'break-all' }} // Break long words
                      />
                    </div>
                    <div className="grid grid-cols-2 items-center">
                      <label className="px-4 py-2 font-semibold">Contact No.</label>
                      <input
                        type="text"
                        name="mobile"
                        value={data.mobile}
                        onChange={handleChange}
                        className="px-4 py-2 border w-full"
                      />
                    </div>
                    <div className="col-span-2 text-right">
                      <button
                        type="submit"
                        className="mt-3 bg-blue-500 text-white py-2 px-4 rounded"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="text-gray-700">
                  <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 text-sm">
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold text-dark">
                        <b>First Name</b>
                      </div>
                      <h3
                        className="font-semibold capitalize"
                        style={{
                          fontSize: '15px',
                          fontWeight: 'bolder',
                          color: 'darkblue',
                        }}
                      >
                        {data.firstName}
                      </h3>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold text-dark">
                        <b>Last Name</b>
                      </div>
                      <h3
                        className="font-semibold capitalize"
                        style={{
                          fontSize: '15px',
                          fontWeight: 'bolder',
                          color: 'darkblue',
                        }}
                      >
                        {data.lastName}
                      </h3>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold text-dark">
                        <b>Contact No.</b>
                      </div>
                      <h3
                        className="font-semibold capitalize"
                        style={{
                          fontSize: '15px',
                          fontWeight: 'bolder',
                          color: 'darkblue',
                        }}
                      >
                        {data.mobile}
                      </h3>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold text-dark">
                        <b>Email</b>
                      </div>
                      <h3
                        className="font-semibold"
                        style={{
                          fontSize: '15px',
                          fontWeight: 'bolder',
                          color: 'darkblue',
                          wordBreak: 'break-word',
                        }}
                      >
                        {data.email}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Profile;
