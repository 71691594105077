import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { add_productURL, imageURL, updateProductURL } from "../api/api";

const AddProduct = () => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData');

    let userType = null;

    if (userData) {
        userType = JSON.parse(userData);
    }

    const initialFormData = {
        name: "",
        brand: "",
        IN_stock: "",
        listing: "",
        product_color: "",
        MRP_price: "",
        selling_price: "",
        discountAmount:"",
        totalAmountAfterDiscount:"",
        category: "",
        material: "",
        weight: "",
        Height: "",
        Width: "",
        Length: "",
        Depth: "",
        description: "",
        SKU_id: "",
        HSN_code: "",
        tax: "",
        Local_charge: "",
        Zonal_charge: "",
        National_charges: "",
        image: [], // For handling image files
        Links: []
    };
      
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "Links") {
          setFormData({
              ...formData,
              Links: value.split(',').map(Link => Link.trim()), // Split by commas and remove extra spaces
          });
      } else {
          setFormData({
              ...formData,
              [name]: value,
          });
      }
    };

    const handleStockChange = (event) => {
        setFormData({ ...formData, IN_stock: event.target.value });
    };

    const handleListingChange = (event) => {
        setFormData({ ...formData, listing: event.target.value });
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to an array
        setFormData({ ...formData, image: files }); // Store all selected files in formData
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const { image, ...productData } = formData;
    
            const response = await axios.post(add_productURL, productData, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
    
            if (image && image.length > 0) {
                const imageFormData = new FormData();
                image.forEach((file) => {
                    imageFormData.append('files', file);
                });
                imageFormData.append('SKU_id', formData.SKU_id);
    
                const imageResponse = await axios.post(imageURL, imageFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
                console.log("Images uploaded successfully:", imageResponse.data);
            }
    
            console.log("Product added successfully", response.data);
            alert("Product added successfully!");
    
            // Reset the form fields
            setFormData(initialFormData); // Reset the form data to initial state
    
        } catch (error) {
            if (error.response) {
                console.error("Error response:", error.response.data);
                alert(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                console.error("No response received:", error.request);
                alert("No response from server. Please try again later.");
            } else {
                console.error("Error adding product:", error.message);
                alert(`An error occurred: ${error.message}`);
            }
        }
    };

    // State to hold multiple fields to update
    const [editData, setEditData] = useState({
        SKU_id: "",
        heading: "",
        value: "",
    });

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [editFields, setEditFields] = useState([{ heading: "", value: "" }]);

    // Handle form changes dynamically for all fields
    const handleEditChange = (index, e) => {
      const { name, value } = e.target;
      const newEditFields = [...editFields];
      newEditFields[index][name] = value;
      setEditFields(newEditFields);
  };
  
    const handleAddField = () => {
      setEditFields([...editFields, { heading: "", value: "" }]);
  };
  
  const selectedFilesRef = useRef([]);

  const handleFileUpdate = (e) => {
      const files = Array.from(e.target.files);
      selectedFilesRef.current = [...selectedFilesRef.current, ...files];
      console.log("Updated files (ref):", selectedFilesRef.current);
  };

// useEffect(() => {
//     console.log("Selected files have been updated:", selectedFiles);
// }, [selectedFiles]);


const editProduct = async (event) => {
    event.preventDefault();

    try {
        const { SKU_id } = editData;

        // Ensure SKU_id is provided
        if (!SKU_id) {
            alert("Please provide a SKU ID");
            return;
        }

        // Check selected files directly from state
        console.log("Current selected files (using state):", selectedFilesRef.current);
        console.log("Selected files length:", selectedFilesRef.current.length);

        // Filter fields for update
        const filterFields = editFields.reduce((obj, field) => {
            if (field.heading === "Links" && field.value !== "") {
                obj[field.heading] = field.value.split(',').map(link => link.trim());
            } else if (field.value !== "") {
                obj[field.heading] = field.value;
            }
            return obj;
        }, {});

        console.log("Fields to update:", filterFields);

        // Validate updates
        if (Object.keys(filterFields).length === 0 && selectedFiles.length === 0) {
            alert("Please fill in the required fields or upload images.");
            return;
        }

        let fieldUpdateMessage = "";
        let imageUpdateMessage = "";

        // Update product details if fields exist
        if (Object.keys(filterFields).length > 0) {
            const fieldUpdateResponse = await axios.put(updateProductURL, { SKU_id, ...filterFields }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log("Response from product update:", fieldUpdateResponse);
            if (fieldUpdateResponse.status === 200) {
                fieldUpdateMessage = fieldUpdateResponse.data.message || "Product fields updated successfully.";
            }
        }

        // Handle image uploads if any files are selected
        if (selectedFilesRef.current.length > 0) {
            const imageFormData = new FormData();
            selectedFilesRef.current.forEach((file) => {
                imageFormData.append('files', file);
            });
            imageFormData.append('SKU_id', SKU_id);

            const imageUpdateResponse = await axios.post(imageURL, imageFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log("Response from image upload:", imageUpdateResponse);
            if (imageUpdateResponse.status === 200) {
                imageUpdateMessage = imageUpdateResponse.data.message || "Images uploaded successfully.";
            }
        } else {
            console.log("No images selected for upload.");
        }

        // Combine messages and display
        const successMessage = [fieldUpdateMessage, imageUpdateMessage].filter(Boolean).join(" ");
        if (successMessage) {
            alert(successMessage);
        } else {
            alert("No updates were made.");
        }

        resetFormFields();

    } catch (error) {
        console.error("Error updating product:", error);
        alert(`An error occurred: ${error.message}`);
    }
};





// Function to reset all fields
const resetFormFields = () => {
  // Reset SKU_id and other editData fields
  setEditData({ SKU_id: "" });

  // Reset editFields to an empty array or default state
  setEditFields([{ heading: "", value: "" }]);

  // Clear selected files for images
  setSelectedFiles([]);
  selectedFilesRef.current = [];
};
 

    // Check if userType is null or if the user is not an admin
    if (!userType) {
        return <div>Unauthorized.</div>;
    }
    if (userType.type === "admin") {
        return (
            <div className="flex flex-col md:flex-row min-h-screen bg-gray-100 justify-start items-start px-4 py-8 gap-4">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto md:mx-4 my-4">
                    <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold text-center mb-4">Add Product</h2>
                    <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Product Name
                        </label>
                            <input
                                className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Product Name"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Brand
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="brand"
                                id="brand"
                                value={formData.brand}
                                onChange={handleChange}
                                placeholder="Brand"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            In Stock
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="IN_stock"
                                id="IN_stock"
                                value={formData.IN_stock}
                                onChange={handleStockChange}
                                placeholder="Express/InStock"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Listing
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="listing"
                                id="listing"
                                value={formData.listing}
                                onChange={handleListingChange}
                                placeholder="Active/Inactive"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Product Color
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="product_color"
                                id="product_color"
                                value={formData.product_color}
                                onChange={handleChange}
                                placeholder="Product Color"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            MRP Price
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="MRP_price"
                                id="MRP_price"
                                value={formData.MRP_price}
                                onChange={handleChange}
                                placeholder="MRP Price"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Selling Price
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="selling_price"
                                id="selling_price"
                                value={formData.selling_price}
                                onChange={handleChange}
                                placeholder="Selling Price"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Category
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="category"
                                id="category"
                                value={formData.category}
                                onChange={handleChange}
                                placeholder="Category"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Material
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="material"
                                id="material"
                                value={formData.material}
                                onChange={handleChange}
                                placeholder="Material"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Weight
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="weight"
                                id="weight"
                                value={formData.weight}
                                onChange={handleChange}
                                placeholder="Weight"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Height
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Height"
                                id="Height"
                                value={formData.Height}
                                onChange={handleChange}
                                placeholder="Height"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Width
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Width"
                                id="Width"
                                value={formData.Width}
                                onChange={handleChange}
                                placeholder="Width"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Length
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Length"
                                id="Length"
                                value={formData.Length}
                                onChange={handleChange}
                                placeholder="Length"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Depth
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Depth"
                                id="Depth"
                                value={formData.Depth}
                                onChange={handleChange}
                                placeholder="Depth"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Description
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                name="description"
                                id="description"
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Product Description"
                                rows="4"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            SKU ID
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="SKU_id"
                                id="SKU_id"
                                value={formData.SKU_id}
                                onChange={handleChange}
                                placeholder="SKU ID"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            HSN Code
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="HSN_code"
                                id="HSN_code"
                                value={formData.HSN_code}
                                onChange={handleChange}
                                placeholder="HSN Code"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Tax
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="text"
                                name="tax"
                                id="tax"
                                value={formData.tax}
                                onChange={handleChange}
                                placeholder="Tax Percentage"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                           Local Charge
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Local_charge"
                                id="Local_charge"
                                value={formData.Local_charge}
                                onChange={handleChange}
                                placeholder="Local Charge"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Zonal Charge
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="Zonal_charge"
                                id="Zonal_charge"
                                value={formData.Zonal_charge}
                                onChange={handleChange}
                                placeholder="Zonal Charge"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                           National Charge
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="number"
                                name="National_charges"
                                id="National_charges"
                                value={formData.National_charges}
                                onChange={handleChange}
                                placeholder="National Charges"
                            />
                        </div>

                        <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Product Links
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
        type="text"
        name="Links"
        id="Links"
        value={formData.Links}
        onChange={handleChange}
        placeholder="Enter multiple links separated by commas"
    />
</div>

<div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor="name">
                            Upload Images
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400"
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleFileChange}
                                multiple
                            />
                        </div>

                        <button type="submit" className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                            Add Product
                        </button>
                    </form>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto md:mx-4 my-4">
                <h2 className="text-2xl font-bold text-center mb-4">Edit Product</h2>
    <form onSubmit={editProduct}>
        <div className="mb-4">
            <label className="block text-md mb-1 font-semibold" htmlFor="SKU_id">
                SKU ID
            </label>
            <input
                className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                type="text"
                name="SKU_id"
                value={editData.SKU_id}
                onChange={(e) => setEditData({ ...editData, SKU_id: e.target.value })}
                placeholder="SKU ID"
            />
        </div>

        {editFields.map((field, index) => (
            <div key={index} className="mb-4">
                <label className="block text-md mb-1 font-semibold" htmlFor={`heading-${index}`}>
                    Field Heading
                </label>
                <select
                    className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                    name="heading"
                    value={field.heading}
                    onChange={(e) => {
                        handleEditChange(index, e);
                        if (e.target.value !== "image") {
                            setSelectedFiles([]);
                        }
                    }}
                >
                    <option value="" disabled>Select Heading</option>
                    <option value="name">Product Name</option>
                    <option value="brand">Brand Name</option>
                    <option value="IN_stock">Stock</option>
                    <option value="listing">Listing</option>
                    <option value="product_color">Product Colour</option>
                    <option value="category">Category</option>
                    <option value="material">Material</option>
                    <option value="weight">Weight</option>
                    <option value="Height">Height</option>
                    <option value="Width">Width</option>
                    <option value="Length">Length</option>
                    <option value="Depth">Depth</option>
                    <option value="MRP_price">MRP</option>
                    <option value="selling_price">Selling Price</option>
                    <option value="Links">Links</option>
                    <option value="image">Images</option>
                </select>

                {/* Render input field for value if heading is selected */}
                {field.heading && field.heading !== 'image' && field.heading !== 'Links' && (
                    <>
                        <label className="block text-md mb-1 font-semibold" htmlFor={`value-${index}`}>
                            Field Value
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                            type="text"
                            name="value"
                            value={field.value}
                            onChange={(e) => handleEditChange(index, e)}
                            placeholder="Enter value"
                        />
                    </>
                )}

                {/* Render input for comma-separated links */}
                {field.heading === 'Links' && (
                    <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor={`value-${index}`}>
                            Enter Links (comma separated)
                        </label>
                        <input
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                            type="text"
                            name="value"
                            value={field.value}
                            onChange={(e) => handleEditChange(index, e)}
                            placeholder="Enter links separated by commas"
                        />
                    </div>
                )}

                {/* Render file input for image fields */}
                {field.heading === 'image' && (
                    <div className="mb-4">
                        <label className="block text-md mb-1 font-semibold" htmlFor={`value-${index}`}>
                            Upload Images
                        </label>
                        <input
                            type="file"
                            multiple
                            onChange={handleFileUpdate}
                            className="form-control w-full border-2 py-2 px-4 rounded-md text-sm outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
                        />
                    </div>
                )}
            </div>
        ))}

        <button
            type="button"
            onClick={handleAddField}
            className="mt-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
        >
            Add Field
        </button>

        <button type="submit" className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
            Update Product
        </button>
    </form>
</div>


            </div>
    )} else {
        return <div>Unauthorized</div>;
    }
};

export default AddProduct;