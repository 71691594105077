 const BASE_URL = 'https://api.digiuncle.co.in';
// const BASE_URL = 'http://localhost:8080'
 
export const signUpURL = `${BASE_URL}/user/create`;
export const LoginURL = `${BASE_URL}/user/login`;
export const userContextURL = `${BASE_URL}/user/get`;
export const userUpdateURL = `${BASE_URL}/user/update`;

export const add_productURL = `${BASE_URL}/product/create`;
export const updateProductURL = `${BASE_URL}/product/update-product`;
export const getproductURL = `${BASE_URL}/product/get`;
export const imageURL = `${BASE_URL}/upload/upload`;
