import React from 'react';
import { Banner, ProductCard } from '../components';
import { Link } from 'react-router-dom';
import { useProductContext } from '../context';
import { SearchBar } from "../components/";
import { IoIosSearch } from "react-icons/io";

const Home = () => {
    const { RecentProduct, loading } = useProductContext();
    const data = JSON.parse(localStorage.getItem("products")) || [];
    const data1 = data.slice(0, 10);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Banner />

            {/* Recently Viewed Section */}
            <div className="py-3">
                {RecentProduct?.length === 0 ? null : (
                    <h2 className="mx-5 text-4xl font-serif font-semibold">
                        Recently Viewed
                    </h2>
                )}
                <div className="px-4">
                    <ProductCard data={RecentProduct} />
                </div>
            </div>

            {/* Best Selling Section */}
            <div className="py-3">
                <h2 className="mx-5 text-4xl font-serif font-semibold">Best Selling</h2>
                <div className="px-4">
                    {data.length > 0 ? (
                        <ProductCard data={data1} />
                    ) : (
                        <p>No products available</p>
                    )}
                </div>
                <Link to="/productlist" className="py-2 flex w-full justify-end items-center px-5">
                    <button className="bg-red-500 text-lg font-medium px-4 py-2 rounded-sm text-white">
                        View All Products
                    </button>
                </Link>
            </div>
        </>
    );
};

export default Home;
