import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactStars from 'react-stars';
import { IoCartOutline } from "react-icons/io5";
import { useCartContext } from '../../context';
import toast from 'react-hot-toast';
import Modal from '../Modal/Modal'; // Import your modal component

const ProductDetail = () => {
  const data = JSON.parse(localStorage.getItem("products")) || [];
  const { id } = useParams();
  const filterdata = data.filter((i) => i._id === id);
  const { addToCart } = useCartContext();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [links, setLinks] = useState([]); // State for links

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  // Fields to exclude from rendering
  const excludedFields = [
    "Links",
    "_id",
    "user_id",
    "image",
    "createdAt",
    "updatedAt"
  ];

  return (
    <>
      {filterdata.map((product) => (
        <div key={product._id}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-8'>

            {/* Product Images */}
            <div>
              <div className='flex justify-between flex-wrap-reverse px-5'>
                <div className='w-full sm:h-[400px] sm:w-[500px]'>
                  {product.image.length > 0 && (
                    <img src={product.image[currentImageIndex]} className="h-full w-full" alt={product.name} />
                  )}
                </div>
              </div>

              {/* Display all images below the main image */}
              <div className='flex space-x-2 overflow-x-auto'>
                {product.image.map((img, index) => (
                  <div key={index} className='h-20 w-20 border cursor-pointer' onClick={() => handleImageClick(index)}>
                    <img src={img} alt={`${product.name} ${index + 1}`} className="h-full w-full object-cover" />
                  </div>
                ))}
              </div>

              {/* Add to Cart and Buy Now Buttons */}
              <div className="grid grid-cols-2 gap-3 mt-4">
                <button className="bg-yellow-500 w-full h-14 text-xl font-medium text-white hover:bg-yellow-600 transition-all duration-200 flex items-center justify-center gap-3"
                  onClick={() => { addToCart(product); toast.success("Item added to cart"); }}>
                  <IoCartOutline /> Add to Cart
                </button>
                {product.Links && product.Links.length > 0 ? (
                  <button
                    className="bg-orange-500 w-full h-14 text-xl font-medium text-white hover:bg-orange-600 transition-all duration-200 flex items-center justify-center gap-3"
                    onClick={() => {
                      setLinks(product.Links); // Set the links for the modal
                      setIsModalOpen(true); // Open the modal
                    }}
                  >
                    Buy Now
                  </button>
                ) : (
                  <button
                    className="bg-orange-500 w-full h-14 text-xl font-medium text-white hover:bg-orange-600 transition-all duration-200 flex items-center justify-center gap-3"
                    onClick={() => {
                      alert("This product is not available to buy online but you can buy this product from this address: Digiuncle Creations, Achievers Center Point, Level-1, Sector 49, Kalindi Hill, Fridabad, Haryana 121001");
                    }}
                  >
                    Buy Now
                  </button>
                )}
              </div>
            </div>

            {/* Product Details */}
            <div className=''>
              <div className='border px-3 py-1 rounded-md'>
                <p className="text-2xl font-medium">{product.name}</p>
                <p className="text-lg font-medium">
                  {product.selling_price ? `₹${product.selling_price}` : "Price not available"}
                  {product.MRP_price && <span className="text-green-500 text-sm"> (MRP: ₹{product.MRP_price})</span>}
                </p>
                {product.discounted && (
                  <p className="line-through text-sm">
                    Discounted Price: ₹{product.discounted}
                  </p>
                )}
                <ReactStars count={5} value={product.rating || 0} size={20} color={'#ffd700'} edit={false} />
              </div>

              {/* Size and Color (if available) */}
              <div className="border px-3 py-2 my-3">
                {product.size && (
                  <>
                    <h2 className="text-xl font-medium">Size</h2>
                    <button value="size" className="border-2 text-md px-3 rounded-full">{product.size}</button>
                  </>
                )}
                {product.product_color && (
                  <>
                    <h2 className="text-xl font-medium">Color</h2>
                    <button value="color" className="border-2 text-md px-3 rounded-full">{product.product_color}</button>
                  </>
                )}
              </div>

              {/* Product Details */}
              <div className="border px-3 rounded-md text-gray-800 py-2">
                <h2 className='font-bold text-2xl'>Details</h2>
                {Object.entries(product).map(([key, value]) => {
                  if (value && !excludedFields.includes(key)) {
                    // Format the key to a label
                    const label = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
                    return (
                      <div key={key} className="my-2">
                        <span className='font-bold'>{label}: </span>
                        <span className='font-medium'>{value}</span>
                      </div>
                    );
                  }
                  return null; // If value is falsy or key is excluded, return null
                })}
              </div>
            </div>
          </div>

          {/* Modal for Links */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} links={links} />
        </div>
      ))}
    </>
  );
};

export default ProductDetail;
