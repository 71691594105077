import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider, CartContextProvider, GetContestProvider, ProductContextProvider, WishlistProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <WishlistProvider>
        <CartContextProvider>
          <ProductContextProvider>
            <BrowserRouter>
              <GetContestProvider>
                <App />
              </GetContestProvider>
            </BrowserRouter>
          </ProductContextProvider>
        </CartContextProvider>
      </WishlistProvider>
    </AuthContextProvider>
  </React.StrictMode >
);


