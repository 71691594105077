import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FaHeart } from 'react-icons/fa';
import $ from 'jquery';
import "./page.css";
import { ProductContext } from '../../context/productContext/ProductContext';
import { useWishlistContext } from '../../context';
import { getproductURL } from "../../api/api"
import { SearchBar } from "..";

const ProductListing = () => {
    const { category } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { addRecentProduct } = useContext(ProductContext);
    const { addToWishlist, Wishlist } = useWishlistContext();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [range, setRange] = useState(10000);
    const [filterPrice, setFilterPrice] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [sortOption, setSortOption] = useState(''); // State to hold the selected sort option
    const [sortedData, setSortedData] = useState([]); // State for sorted data



    // Fetch product data from API
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(getproductURL);
                const fetchedData = Array.isArray(res.data.data) ? res.data.data : [];
                setData(fetchedData);
                setFilterData(fetchedData);
                setFilterPrice(fetchedData);
            } catch (error) {
                console.error("Error fetching data:", error);
                setData([]);
            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    // Get unique category names
    const uniqueValues = Array.from(new Set(data.map((item) => item.category)));

    const handleSort = (option, dataToSort) => {
        setSortOption(option);
        let sortedArray;
    
        switch (option) {
            case 'Price--Low to High':
                sortedArray = [...dataToSort].sort((a, b) => a.selling_price - b.selling_price);
                break;
            case 'Price--High to Low':
                sortedArray = [...dataToSort].sort((a, b) => b.selling_price - a.selling_price);
                break;
            case 'Newest first':
                sortedArray = [...dataToSort].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            default:
                sortedArray = dataToSort; // Keep the original order
        }
    
        return sortedArray;
    };
    
    const handleClick = (value) => {
        navigate(`/productlist/${value}`);
        setSelectedCategory(value);
    
        let filtered = data.filter((item) => item.category === value);
        filtered = handleSort(sortOption, filtered); // Apply sorting after filtering by category
        setFilterPrice(filtered);
    };
    
    const handleChange = (value) => {
        const numericValue = Number(value);
        setRange(numericValue);
    
        let filtered = data.filter((item) => {
            return (!selectedCategory || item.category === selectedCategory) && item.MRP_price <= numericValue;
        });
    
        filtered = handleSort(sortOption, filtered); // Apply sorting after filtering by price range
        setFilterPrice(filtered);
    };
    

    // Toggle category visibility
    const handleToggle = () => {
        setIsCategoryOpen(prev => !prev);
        $("#toggle").slideToggle("slow");
    };

    // Check if the item is in the wishlist
    const isInWishlist = (itemId) => {
        return Array.isArray(Wishlist) && Wishlist.some((wishlistItem) => wishlistItem._id === itemId);
    };

// Determine which data to display
const displayedData = filterPrice.length > 0 ? filterPrice : data;



 // Sort displayedData based on the selected sort option
 const sortedDisplayedData = [...displayedData].sort((a, b) => {
     switch (sortOption) {
         case 'Price--Low to High':
             return a.selling_price - b.selling_price;
         case 'Price--High to Low':
             return b.selling_price - a.selling_price;
         case 'Newest first':
             return new Date(b.createdAt) - new Date(a.createdAt);
         default:
             return 0; // Keep original order
     }
 });

    // Display loading indicator if loading
    if (loading) {
        return <div>Loading...</div>;
    }

    const handleProductClick = (item) => {
        addRecentProduct(item);
        navigate(`/product/${item._id}`);
    };

    const handleClearFilters = () => {
        setRange(10000); // Reset range to initial value
        setFilterData(data); // Reset filter data to original data
        setFilterPrice(data); // Reset filter price to original data
        setSelectedCategory(null); // Reset the selected category
        setSortOption(''); // Reset the sort option to default
        setSortedData(data); // Reset sorted data to the original data
        navigate("/productlist"); // Optionally, navigate to the product list page
    };
    
    

    return (
        <div className="h-full w-full px-6 pt-5 flex flex-col md:flex-row justify-between">
            {/* Sidebar for filters */}
            <div className="h-full w-full md:w-[20%] bg-gray-100 mb-4 md:mb-0">
                <div>
                    <h2 className="text-xl font-medium py-2 font-sans px-3 flex justify-between">
                        <span>Filter</span>
                        <span>
                            <button
                                onClick={handleClearFilters} // Use button to trigger clear function
                                className="border border-black rounded-full text-sm px-2 py-1 hover:bg-black hover:text-white duration-500"
                            >
                                Clear
                            </button>
                        </span>
                    </h2>
                    <hr />
                </div>

                <div>
                    <h2
                        className="text-lg font-medium text-gray-700 py-2 font-sans px-3 flex justify-between items-center"
                        onClick={handleToggle}
                    >
                        <span>CATEGORIES</span>
                        <span id="arrow" className={isCategoryOpen ? "rotate-0" : "rotate-[-180deg]"}>
                            <IoIosArrowDown />
                        </span>
                    </h2>
                    <hr />
                    <form className={`w-full h-fit py-3 ${isCategoryOpen ? "" : "hidden"}`} id="toggle">
                    {uniqueValues.map((item) => (
    <div className="flex gap-3 px-3" key={item} onClick={() => handleClick(item)}>
        <input
            type="radio"
            id={item}
            name="options"
            value={item}
            checked={selectedCategory === item} // Check if this category is selected
            onChange={() => handleClick(item)} // Use onChange for accessibility
        />
        <label htmlFor={item}>{item}</label>
    </div>
))}

                    </form>
                </div>

                <div>
                    <h2 className="text-xl font-medium py-2 font-sans px-3 flex justify-between">Range</h2>
                    <input
                        type="range"
                        min={100}
                        max={10000}
                        value={range}
                        className="mx-3 ps-2"
                        onChange={(e) => handleChange(e.target.value)}
                    />
                    <p className="px-3 flex w-full justify-between">
                        <span className="border border-gray-600 px-5">Min</span>
                        <button className="px-5 border-gray-600 border">₹{range}</button>
                    </p>
                    <hr />
                </div>
            </div>

            {/* Main product display area */}
            <div className="h-full w-full md:w-[78%]">
                {/* Breadcrumb */}
                <ol className="flex items-center whitespace-nowrap" aria-label="Breadcrumb">
                    <li className="inline-flex items-center">
                        <Link to="/" className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none">
                            Home
                        </Link>
                        <IoIosArrowForward />
                    </li>
                    <li className="inline-flex items-center">
                        <Link to={location.pathname} className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none">
                            Product List
                        </Link>
                        <IoIosArrowForward />
                    </li>
                </ol>

                {/* Sorting Options */}
              {/* Sorting Options */}
<div className="py-2 flex gap-5 flex-wrap">
    <span className="font-medium">Sort By</span>
    <button 
        type="button"
        className={`shorted ${sortOption === 'Price--Low to High' ? 'selected' : ''}`} 
        onClick={() => setSortOption('Price--Low to High')}
    >
        Price--Low to High
    </button>
    <button 
        type="button"
        className={`shorted ${sortOption === 'Price--High to Low' ? 'selected' : ''}`} 
        onClick={() => setSortOption('Price--High to Low')}
    >
        Price--High to Low
    </button>
    <button 
        type="button"
        className={`shorted ${sortOption === 'Newest first' ? 'selected' : ''}`} 
        onClick={() => setSortOption('Newest first')}
    >
        Newest first
    </button>
</div>



        
                <hr />

                {/* Product grid layout */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-5 py-2 cursor-pointer">
                    {sortedDisplayedData.length > 0 ? (
                        sortedDisplayedData.map((item) => (
                            <div
                                className="bg-gray-100 transition-all duration-500 shadow-md relative"
                                onClick={() => handleProductClick(item)}
                                key={item._id}
                            >
                                <div className="h-48 bg-gray-300">
                                    {item.image && item.image.length > 0 ? (
                                        <img
                                            src={item.image[0]}
                                            alt={item.name}
                                            className="h-full w-full object-cover"
                                        />
                                    ) : (
                                        <div className="flex items-center justify-center h-full text-gray-500">
                                            No Image Available
                                        </div>
                                    )}
                                </div>
                                <div className="px-4 w-full bg-white">
                                    <p>
                                        <span className="font-medium">₹{item.selling_price || "N/A"}</span>
                                        {/* {item.MRP_price && (
                                            <span className="text-green-500 font-medium text-xs">
                                                ₹{item.MRP_price} (Discounted)
                                            </span>
                                        )} */}
                                    </p>
                                    <p className="font-medium text-sm text-gray-500">
                                        {item.brand || "Brand Not Specified"}
                                    </p>
                                    <h2 className="font-medium">
                                        {item.name.length > 21 ? `${item.name.slice(0, 21)}...` : item.name}
                                    </h2>
                                    <button 
                                        className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the event from bubbling up to the link
                                            addToWishlist(item);
                                        }}
                                    >
                                        {/* Change the heart color based on wishlist status */}
                                        <FaHeart className={`text-gray-500 ${isInWishlist(item._id) ? 'text-red-500' : ''}`} />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No products found</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductListing;
