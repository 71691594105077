import React, { createContext, useEffect, useReducer } from 'react';
import { reducer2 } from '../../reducer';
import toast from 'react-hot-toast';

export const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
    // Fetch wishlist data from localStorage or return an empty array if it doesn't exist
    const Getdata = () => {
        const data = localStorage.getItem("Wishlist");
        if (!data) {
            return []; // Ensure the wishlist is initialized as an empty array if no data is found
        } else {
            try {
                return JSON.parse(data); // Ensure proper parsing of localStorage data
            } catch (error) {
                console.error("Error parsing Wishlist data from localStorage", error);
                return []; // Fallback to an empty array if parsing fails
            }
        }
    };

    const initialstate = {
        Wishlist: Getdata(), // Initialize with the fetched wishlist data
    };

    const [state, dispatch] = useReducer(reducer2, initialstate);

    const addToWishlist = (item) => {
        dispatch({ type: "ADD_TO_WISHLIST", payload: item });
        toast.success("Item Added to Wishlist");
    };

    const removeToWish = (item) => {
        dispatch({ type: "REMOVE_TO_WISHLIST", payload: item._id });
        toast.success("Item removed from Wishlist");
    };

    // Update localStorage whenever the Wishlist state changes
    useEffect(() => {
        localStorage.setItem("Wishlist", JSON.stringify(state.Wishlist));
    }, [state.Wishlist]);

    return (
        <WishlistContext.Provider value={{ ...state, addToWishlist, removeToWish }}>
            {children}
        </WishlistContext.Provider>
    );
};

export default WishlistProvider;
