import React from 'react';

// Function to extract website name from URL
const getWebsiteName = (url) => {
  try {
    const urlObject = new URL(url);
    const hostname = urlObject.hostname.replace('www.', ''); // Remove 'www.' if present
    const websiteName = hostname.charAt(0).toUpperCase() + hostname.slice(1); // Capitalize first letter
    return websiteName;
  } catch (error) {
    console.error("Invalid URL", error);
    return "Link"; // Fallback name
  }
};

const Modal = ({ isOpen, onClose, links }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Choose a link to open:</h2>
        <ul className="list-disc ml-5 space-y-2">
          {links.map((link, index) => (
            <li key={index}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {getWebsiteName(link)} {/* Use the website name instead of Link */}
              </a>
            </li>
          ))}
        </ul>
        <button
          className="mt-4 bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 transition duration-200"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
